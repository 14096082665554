.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15001;
  background-color: black;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 0.3s, opacity 0.3s ease-in;
}

.closeButton,
.closeButton:focus,
.closeButton:not(:disabled):not(.disabled):active {
  color: white!important;
  background: transparent;
  border: 0;
  outline: 0;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8em;
  font-size: 2.0rem;
}

.showSlide {
  opacity: 1;
  visibility: visible;
  transition: 0.4s ease-out;
}

.hideSlide {
  opacity: 0;
  visibility: hidden;
}

.mobileMenuLogo {
  position: absolute;
  width: 100vw;
  text-align: center;
  top: 20vh;
  font-family: "Yanone Kaffeesatz", "Helvetica";
  font-style: normal;
  font-size: 5.0vh;
  color: white;
}

.mobileMenuLogo img {
  width: 15vh;
}

.menuListContainer {
  position: absolute;
  bottom: 3vh;
}

.menuList {
  width: 100vw;
  list-style: none;
  padding: 1rem;
  padding-left: 10vw;
  margin: 0 auto;
  background-color: transparent;
}

.menuList li {
  display: block;
  box-sizing: border-box;
  line-height: 1.25;
  font-size: 1.6rem;
  padding: 0.5em;
  border-bottom: 1px solid #333;
  cursor: pointer;
  color: white;
  font-family: "Yanone Kaffeesatz", "Helvetica";
}

.menuList li:first-child{
  border-top: 1px solid #333;
}

.menuList li:last-child{
  border: none;
  background-color: #222;
  margin-top: 5vh;
  border-radius: 5px;
}

.menuList li span {
  width: 200px!important;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 1rem;
  color: white;
}