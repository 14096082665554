.Theme {
  --text-color: black;
  --logo-tiefbau-color: black;
  --logo-portal-color: black;
  --background-color: white;
  --plate-text-color: black;
  --plate-background-color: #f8f9fa;
  --plate-border-radius: 4px;
  --btn-primary-background-color: #eee;
  --btn-primary-hover-filter: brightness(97%);
  --btn-danger-background-color: darkred;
  --btn-danger-text-color: white;
  --btn-success-background-color: darkgreen;
  --btn-success-text-color: white;
  --border-over-background-color: #bbb;
  --text-passive-color: grey;
  --plate-hover-text-color: black;
  --plate-hover-background-color: #eee;
  --input-background-color: white;
  --input-text-color: black;
  --loader-color: black;
  --success-color: darkgreen;
  --danger-color: darkred;
  --disabled-color: grey;
  --note-background-color: rgba(255, 229, 100, .3);
  --note-background-secondary-color: orange;
  --note-text-color: black;
}

.Theme.dark {
  --text-color: #ccc;
  --logo-tiefbau-color: #c8d400;
  --logo-portal-color: #c8d400;
  --background-color: #0c0c0c;
  --plate-text-color: #ddd;
  --plate-background-color: #141414;
  --plate-border-radius: 4px;
  --plate-hover-text-color: #ddd;
  --plate-hover-background-color: #222;
  --btn-primary-background-color: #282828;
  --btn-primary-hover-filter: brightness(140%);
  --btn-danger-background-color: darkred;
  --btn-danger-text-color: white;
  --btn-success-background-color: darkgreen;
  --btn-success-text-color: white;
  --border-over-background-color: #333;
  --text-passive-color: grey;
  --input-background-color: #0c0c0c;
  --input-text-color: #ddd;
  --loader-color: white;
  --success-color: #c8d400;
  --danger-color: rgb(255, 115, 0);
  --disabled-color: grey;
  --note-background-color: rgb(255, 94, 0);
  --note-background-secondary-color: orange;
  --note-text-color: black;
}

.Theme.ewenetz {
  --text-color: black;
  --logo-tiefbau-color: #0077b4;
  --logo-portal-color: #c8d400;
  --background-color: white;
  --plate-text-color: black;
  --plate-background-color: #f8f9fa;
  --plate-border-radius: 0px;
  --btn-primary-background-color: #eee;
  --btn-primary-hover-filter: brightness(97%);
  --btn-danger-background-color: darkred;
  --btn-danger-text-color: white;
  --btn-success-background-color: darkgreen;
  --btn-success-text-color: white;
  --border-over-background-color: #bbb;
  --text-passive-color: grey;
  --plate-hover-text-color: black;
  --plate-hover-background-color: #eee;
  --input-background-color: white;
  --input-text-color: black;
  --loader-color: black;
  --success-color: darkgreen;
  --danger-color: darkred;
  --disabled-color: grey;
  --note-background-color: rgba(255, 229, 100, .3);
  --note-background-secondary-color: orange;
  --note-text-color: black;
}

.Theme.blue {
  --text-color: #ddd;
  --logo-tiefbau-color: #c8d400;
  --logo-portal-color: #c8d400;
  --background-color: rgb(7,9,15);
  --plate-text-color: #ccc;
  --plate-background-color: rgb(15,15,30);
  --plate-border-radius: 4px;
  --plate-hover-text-color: #ccc;
  --plate-hover-background-color: rgb(25,25,60);
  --btn-primary-background-color:rgb(25,25,60);
  --btn-primary-hover-filter: brightness(140%);
  --btn-danger-background-color: darkred;
  --btn-danger-text-color: white;
  --btn-success-background-color: darkgreen;
  --btn-success-text-color: white;
  --border-over-background-color: rgb(25,25,60);
  --text-passive-color: grey;
  --input-background-color: rgb(7,9,15);
  --input-text-color:  #ddd;
  --loader-color: #ddd;
  --success-color: #c8d400;
  --danger-color: rgb(255, 115, 0);
  --disabled-color: grey;
  --note-background-color: rgb(255, 94, 0);
  --note-background-secondary-color: orange;
  --note-text-color: black;
}