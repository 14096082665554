:root {
  --gas-color: darkred;
  --tk-color: darkblue;
  --wasser-color: lightblue;
  --strom-color: #FFCC00;
  --baustrom-color: darkgreen;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  background: var(--background-color);
}

body {
  height: 100vh!important;
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--background-color);
  margin: 0 auto;
  padding-top: 65px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.leaflet-container {
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100vh;
}

.noborder {
  border: 0!important;
  outline: none!important;
}

#root {
  flex: 1 0 auto!important;
  display: flex;
  flex-direction: column;
  height: 100%!important;
}

.hidden {
  display: none;
}

.display-flex {
  display: flex;
}

.opacity {
  opacity: 0.5;
}

.scratch-plate {
  background-color: var(--plate-background-color)!important;
  color: var(--plate-text-color)!important;
  border: 0!important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: var(--text-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  border-radius: var(--plate-border-radius);
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--btn-primary-background-color)!important;
  border: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn:hover {
  filter: var(--btn-primary-hover-filter);
}

.btn:disabled {
  color: var(--text-color)!important;
  background-image: linear-gradient(45deg, var(--plate-hover-background-color) 16.67%,var(--plate-background-color) 16.67%, var(--plate-background-color) 50%, var(--plate-hover-background-color) 50%, var(--plate-hover-background-color) 66.67%, var(--plate-background-color) 66.67%, var(--plate-background-color) 100%)!important;
  background-size: 4.24px 4.24px!important;
}

.btn-danger {
  display: inline-block;
  font-weight: 400;
  color: var(--btn-danger-text-color);
  border-radius: var(--plate-border-radius);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--btn-danger-background-color)!important;
  border: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: var(--plate-border-radius);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-danger:hover {
  filter: var(--btn-primary-hover-filter)!important;
}

.btn-danger:disabled {
  color: var(--btn-danger-text-color)!important;
  background-color: var(--btn-danger-background-color);
  opacity: 0.6; 
}

.btn-success {
  display: inline-block;
  font-weight: 400;
  color: var(--btn-success-text-color);
  border-radius: var(--plate-border-radius);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--btn-success-background-color)!important;
  border: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: var(--plate-border-radius);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-success:hover {
  filter: var(--btn-primary-hover-filter)!important;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  display: inline-block;
  color: var(--text-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0;
  outline: 0;
  text-decoration: none;
  padding: 0 0.75rem;
}

.scratch-plate-loading,
.btn.btn-link.scratch-plate-loading {
  background-image: linear-gradient(45deg, var(--plate-background-color) 16.67%, var(--background-color) 16.67%, var(--background-color) 50%, var(--plate-background-color) 50%, var(--plate-background-color) 66.67%, var(--background-color) 66.67%, var(--background-color) 100%)!important;
  background-size: 4.24px 4.24px!important;
  color: #999!important;
  border: 0!important;
  text-decoration: none;
}

.dropdown-menu {
  z-index: 1032;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Yanone Kaffeesatz", "Helvetica";
}

.form-control:disabled,
.form-control[readOnly] {
  background-color: white;
  opacity: 1;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

.plate-center {
  border-radius: 0px 0px 0px 0px!important;
}

.plate-left {
  border-radius: var(--plate-border-radius) 0px 0px var(--plate-border-radius)!important;
}

.plate-right {
  border-radius: 0px var(--plate-border-radius) var(--plate-border-radius) 0px!important;
}

.plate-all {
  border-radius: var(--plate-border-radius)!important;
}

.cursor-pointer {
  cursor: pointer;
}

.flexVCenterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--text-color);
  background-color: var(--plate-background-color);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #eee;
  color: red;
}

input,
input:focus,
input:link,
input:hover,
input:visited,
input:active,
button,
button:focus,
button:link,
button:hover,
button:visited,
button:active,
button:active:hover,
button:active:focus,
.btn,
.btn:focus,
.btn:link,
.btn:hover,
.btn:visited,
.btn:active,
.btn:active:hover,
.btn:active:focus,
.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover no-outline {
  outline: 0!important;
  outline-offset: 0!important;
  background-image: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

.dropzone {
  font-size: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 3px;
  border: 3px var(--border-over-background-color) dashed;
  outline: none;
  background-color: var(--background-color);
  color: var(--text-color);
  cursor: pointer;
  transition: border .24s ease-in-out;
}

.dropzone:hover {
  border-color: var(--plate-hover-background-color);
  color: var(--text-color)!important;
  color: rgba(var(--text-color), 1);
  background-color: var(--plate-background-color);
}

.dropzone:active,
.dropzone:focus,
.dropzone:active:focus,
.dropzone:focus:active {
  border: 3px var(--plate-hover-background-color) dashed;
  color: var(--text-color);
  color: rgba(var(--text-color), 1);
}

.dropzone-dropping {
  border: 3px var(--plate-hover-background-color) dashed;
  color: var(--text-color);
  background-color: var(--plate-background-color);
}

.text-unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.round-corners {
  border-radius: var(--plate-border-radius);
}

.bg-transparent {
  background: transparent!important;
}