#footer {
  margin-top: auto;
  padding-top: 30px;
  min-height: 12rem;
}

.footerTitle {
  font-family: 'Yanone Kaffeesatz', sans-serif, "Helvetica";
  font-size: 150%;
  font-weight: 600;
  font-style: normal;
}

.footerCol {
  height: 100%;
}