.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
}

a {
  color: var(--text-color);
}

a:hover {
  text-decoration: none;
  color: var(--text-color);
}

#page-container {
  display:flex; 
  flex-direction: column;
  height: auto;
  width: 100%;
  position: absolute;
  top: 65px;
  bottom: 0;
  background-color: var(--background-color);

  /* Scrollbar */
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--plate-hover-background-color) var(--background-color);
}

#page-container::-webkit-scrollbar {
  width: 11px;
}

#page-container::-webkit-scrollbar-track {
  background: var(--background-color);
}

#page-container::-webkit-scrollbar-thumb {
  background-color: var(--plate-hover-background-color);
  border-radius: var(--plate-border-radius);
  border: 0px solid yellow;
}

#page-container::-webkit-scrollbar-thumb:active {
  filter: var(--btn-primary-hover-filter);
  border-radius: var(--plate-border-radius);
  border: 0px solid yellow;
}

#content-wrap {
  flex: 1;
  color: var(--text-color);
}

.normalText {
  color: var(--text-color);
}

.normalBackground {
  background: var(--background-color);
}

.plateText {
  color: var(--plate-text-color);
}

.plateBackground {
  background: var(--plate-background-color);
}

.plateBackgroundAsText {
  color: var(--plate-background-color);
}

.form-control,
.form-control:focus,
.form-control:active,
.form-control:hover,
.form-control:disabled,
.form-control[readOnly] {
  background: var(--input-background-color);
  color: var(--input-text-color);
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  line-height: 1.5;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--input-text-color)!important;
  -webkit-box-shadow:0 0 0 250px var(--input-background-color) inset!important; /* Change the color to your own background color */
  box-shadow: 0 0 0px 1000px var(--input-background-color) inset!important;
  transition: background-color 5000s ease-in-out 0s!important;
}

input:-webkit-autofill::first-line {
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.cookieconsent-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .cookieconsent-container {
    padding: 0 20% 0 20%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .cookieconsent-container {
    padding: 0 15% 0 15%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cookieconsent-container {
    padding: 0 10% 0 10%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cookieconsent-container {
    padding: 0;
  }
}

.navbar-toggler,
.navbar-toggler:hover,
.navbar-toggler:active,
.navbar-toggler:focus {
  border: none;
  outline: none;
}

.menuButton,
.menuButton:focus,
.menuButton:link,
.menuButton:visited,
.menuButton:active:focus,
.menuButton:active:focus:hover,
.menuButton>.btn-primary.dropdown-toggle {
  border-radius: 0;
  height: 100%;
  background-color: transparent!important;
  color: var(--plate-text-color);
  border-color: transparent!important;
  outline: none!important;
}

.menuButton:hover,
.menuButton.show {
  border-radius: 0;
  height: 100%;
  background: var(--plate-hover-background-color)!important;
  color: red!important;
  border-color: transparent!important;
  outline: none!important;
}

.menuButton:not(:disabled):not(.disabled):active,
.menuButton.show>.btn-primary.dropdown-toggle,
.menuButton.show {
  background-color: transparent;
  color: var(--plate-text-color);
  border-color: transparent;
}

.dropdown-menu {
  color: var(--text-color);
  padding: var(--plate-border-radius) 0;
  background: var(--background-color)!important;
  border-radius: var(--plate-border-radius);
  border: 1px solid var(--border-over-background-color);
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  transition: opacity 0.3s ease;
}


.dropdown-menu.show {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu>.dropdown-divider {
  margin: 0.4rem 0;
}

.dropdown-menu>.dropdown-item,
.dropdown-menu>.dropdown-item-text {
  padding: 0.6rem 1.5rem;
}

.dropdown-item.active,
.dropdown-item:hover {
  background: var(--plate-background-color);
}

.dropdown-item {
  color: var(--text-color);
}

.dropdown-item:hover {
  color: var(--text-color);
  background: var(--plate-background-color);
}

.dropdown-divider {
  border-top: 1px solid var(--plate-background-color);
}

.nav-item.nav-link:first-child {
  margin-left: 3rem;
}

.nav-item.nav-link {
  width: 3rem;
  text-align: center;
}

hr {
  border-color: var(--plate-hover-background-color);
}


.bg-red {
  background-color: darkred!important;
}

.bg-transparent {
  background: transparent;
}

.text-gas {
  color: darkred;
}

.text-tk {
  color: darkblue;
}

.text-wasser {
  color: lightblue;
}

.text-strom {
  color: #FFCC00;
}

.text-baustrom {
  color: darkgreen;
}

.text-blue {
  color: darkblue;
}

.text-darkblue {
  color: darkblue;
}

.text-darkgrey {
  color: #222;
}

.text-red {
  color: red;
}

.text-danger {
  color: var(--danger-color);
}

.text-disabled {
  color: var(--disabled-color);
}

.text-success {
  color: var(--success-color)!important;
}

.text-darkgreen {
  color: darkgreen;
}

.text-passive {
  color: var(--text-passive-color);
}

.text-darkwhite {
  color: #efefef!important;
}

.text-black {
  color: black;
}

.react-contextmenu,
.react-contextmenu--visible {
  background: var(--plate-background-color);
  color: var(--plate-text-color);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: var(--plate-border-radius);
  transition: visibility 0s 0.3s, opacity 0.3s;
}

.react-contextmenu-item {
  cursor: pointer;
  background: var(--plate-background-color);
  color: var(--plate-text-color);
  padding: 0.3rem 1.0rem 0.3rem 0.5rem;
  margin-right: 0rem;
}

.react-contextmenu-item:first-child {
  margin-top: 0.5rem;
}

.react-contextmenu-item:last-child {
  margin-bottom: 0.5rem;
}

.react-contextmenu-item:hover,
.react-contextmenu-item:focus {
  background: var(--plate-hover-background-color);
  outline: none;
  border: none;
}

.react-contextmenu-item>.contextmenuicon {
  display: inline-flex;
  min-width: 40px;
  justify-content: center;
}

.react-contextmenu-item--divider,
.react-contextmenu-item--divider:hover {
  cursor: default;
  border: 0;
  background-color: var(--plate-text-color);
  height: 1px;
  padding: 0;
  margin: 0.3rem 0.5rem 0.3rem 0.5rem;
}

#navbar {
  height: 65px;
  background-color: var(--plate-background-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1030;
}

#navbar-container {
  display: flex;
}

#logo {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Yanone Kaffeesatz', sans-serif, "Helvetica";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-size: 32px;
}

#navbar-menu {
  height: 100%;
  flex: 1;
  display: flex;
}

#navbar-icons {
  margin-left: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.navbar-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color)!important;
  transition: background-color 0.2s linear;
  min-width: 3rem;
}

.navbar-icon.active {
  background-color: var(--plate-hover-background-color)!important;
}

.navbar-icon:hover {
  background: var(--plate-hover-background-color);
}

#language-switch {
  text-transform: uppercase;
}

#navbar-mobilemenu-button {
  margin-left: auto;
}